import { setCookie, getCookie, hasCookie, deleteCookie } from "cookies-next";
import jwt_decode, { JwtPayload } from "jwt-decode";

const ACCESS_TOKEN_KEYWORD = "_wer";
const REFRESH_TOKEN_KEYWORD = "_ruy";
const ACCESS_TOKEN_EXPIRY_KEYWORD = "_aexp";
const REFRESH_TOKEN_EXPIRY_KEYWORD = "_rexp";
const VENDOR_ID_KEYWORD = "_vte";

const setAccessExpiryCookie = (time: number, ctx?: { req: any; res: any }) => {
  if (ctx) {
    setCookie(ACCESS_TOKEN_EXPIRY_KEYWORD, time, ctx);
  } else {
    setCookie(ACCESS_TOKEN_EXPIRY_KEYWORD, time);
  }
};

const setRefreshExpiryCookie = (time: number, ctx?: { req: any; res: any }) => {
  if (ctx) {
    setCookie(REFRESH_TOKEN_EXPIRY_KEYWORD, time, ctx);
  } else {
    setCookie(REFRESH_TOKEN_EXPIRY_KEYWORD, time);
  }
};

export const setVendorIdCookie = (
  time: number,
  ctx?: { req: any; res: any }
) => {
  if (ctx) {
    setCookie(VENDOR_ID_KEYWORD, time, ctx);
  } else {
    setCookie(VENDOR_ID_KEYWORD, time);
  }
};

export const hasVendorId = (ctx?: { req: any; res: any }) => {
  if (ctx) {
    return hasCookie(VENDOR_ID_KEYWORD, ctx);
  } else {
    return hasCookie(VENDOR_ID_KEYWORD);
  }
};

export const hasAccessCookie = (ctx?: { req: any; res: any }) => {
  if (ctx) {
    return hasCookie(ACCESS_TOKEN_KEYWORD, ctx);
  } else {
    return hasCookie(ACCESS_TOKEN_KEYWORD);
  }
};

export const hasRefreshCookie = (ctx?: { req: any; res: any }) => {
  if (ctx) {
    return hasCookie(REFRESH_TOKEN_KEYWORD, ctx);
  } else {
    return hasCookie(REFRESH_TOKEN_KEYWORD);
  }
};

export const deleteAccessCookie = (ctx?: { req: any; res: any }) => {
  if (ctx) {
    deleteCookie(ACCESS_TOKEN_KEYWORD, ctx);
    deleteCookie(ACCESS_TOKEN_EXPIRY_KEYWORD, ctx);
  } else {
    deleteCookie(ACCESS_TOKEN_KEYWORD);
    deleteCookie(ACCESS_TOKEN_EXPIRY_KEYWORD);
  }
};

export const deleteRefreshCookie = (ctx?: { req: any; res: any }) => {
  if (ctx) {
    deleteCookie(REFRESH_TOKEN_KEYWORD, ctx);
    deleteCookie(REFRESH_TOKEN_EXPIRY_KEYWORD, ctx);
  } else {
    deleteCookie(REFRESH_TOKEN_KEYWORD);
    deleteCookie(REFRESH_TOKEN_EXPIRY_KEYWORD);
  }
};

export const setAccessCookie = (
  access_token: string,
  ctx?: { req: any; res: any }
) => {
  let decoded = jwt_decode<JwtPayload>(access_token);
  if (decoded?.exp) {
    setAccessExpiryCookie(decoded?.exp);
  }
  if (ctx) {
    setCookie(ACCESS_TOKEN_KEYWORD, access_token, ctx);
  } else {
    setCookie(ACCESS_TOKEN_KEYWORD, access_token);
  }
};

export const setRefreshCookie = (
  refresh_token: string,
  ctx?: { req: any; res: any }
) => {
  let decoded = jwt_decode<JwtPayload>(refresh_token);
  if (decoded?.exp) {
    setRefreshExpiryCookie(decoded?.exp);
  }
  if (ctx) {
    setCookie(REFRESH_TOKEN_KEYWORD, refresh_token, ctx);
  } else {
    setCookie(REFRESH_TOKEN_KEYWORD, refresh_token);
  }
};

export const getAccessCookie = (ctx?: { req: any; res: any }) => {
  if (ctx) {
    const accessToken = getCookie(ACCESS_TOKEN_KEYWORD, ctx);
    return accessToken;
  } else {
    const accessToken = getCookie(ACCESS_TOKEN_KEYWORD);
    return accessToken;
  }
};

export const getRefreshCookie = (ctx?: { req: any; res: any }) => {
  if (ctx) {
    const refreshToken = getCookie(REFRESH_TOKEN_KEYWORD, ctx);
    return refreshToken;
  } else {
    const refreshToken = getCookie(REFRESH_TOKEN_KEYWORD);
    return refreshToken;
  }
};
