import * as React from "react";
import { icons, IconType } from "@utils/icon";

interface IconProps {
  iconName: IconType;
}

const Icon: React.FC<IconProps> = ({ iconName }) => {
  return <>{icons[iconName]}</>;
};

export default Icon;
